.hends {
  display: flex;
  padding: 0 150px;
}

.hends div {
  flex-basis: 15%;
  margin: auto;
}

.hends img {
  width: 100%;
}

button:disabled{
  cursor: not-allowed;
}

.shake {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0%, 30% { transform: translate(1px, 0) rotate(0deg); }
  
  40% { transform: translate(1px, 0) rotate(1deg); }
  50% { transform: translate(-1px, 0) rotate(-1deg); }
  60% { transform: translate(-3px, 0) rotate(0deg); }
  70% { transform: translate(3px,0) rotate(-1deg); }
  80% { transform: translate(-1px, 0) rotate(1deg); }
  90% { transform: translate(1px, 0) rotate(0deg); }
  100% { transform: translate(1px, 0) rotate(-1deg); }
}