/* style for the riddle */
.light{
    
    margin: 1%;
    display: inline-block;
    width: 8%;
    height: 160px;

}

.light{
    position: relative;
}

.light::after{
    content: '\f0eb';
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    line-height: 100%;
    font-size: 100px;
    width: 100%;
    height: 100%;
    color: rgb(160, 151, 151);   
}

.light.on::after{
    color: yellow;
}

a{
    box-sizing: initial;
    padding: 3%;
    border: solid 1px;
    border-radius: 10px;
    display: block;
    height: 100px;
    width: 70px;
    margin: auto;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}
img{
    width: 100%;
    height: 70%;
}
h3{
    text-align: center;
}

/* end style riddle */


/* style for light game */
.disabled{
    pointer-events: none;
}

.all_cubes{
    position: relative;
    justify-content: space-between;
    max-width: min-content;
    margin: 10px auto 0;
    color: black;
}

.row_cubes{
    display: flex;
    justify-content: space-around;
}

.cube{
    position: relative;
    cursor: pointer;
    margin: 0 0.7% 0.7% 0;
    background-color: rgb(14, 13, 13);
}

.cube::before{
    content: '\f119';
    position: absolute;
    width: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Font Awesome 5 Free';
    text-align: center;
    font-size: 2em;
    color: rgb(241, 241, 20);
}

.cube.on{
    background-color:rgb(241, 241, 20);
}

.cube.next{
    border: solid 5px rgb(247, 14, 14);
}

.cube.on::before{
    color: rgb(14, 13, 13);
    content: '\f118';
}


.winner {
    position: absolute;
    z-index: 1;
    width: 60%;
    margin-left: 20%;
    margin-top: 18.5%;
    pointer-events: none;
}

.winner h1 {
    margin-bottom: -40px;
    z-index: 2;
    position: relative;
}

.btn-warning, .btn-warning:focus{
    background-color:  rgb(241, 241, 20);
}
.btn-warning:hover{
    background-color: black;
    color:  rgb(241, 241, 20);
}
.title{
    width: fit-content;
    position: relative;
    --borderWidth: 3px;
    border-radius: var(--borderWidth);
    padding: 0.3%;
    margin: 8px auto;
}
.title:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background:linear-gradient(60deg, #2c2a28, #f3ee55, #f1f114,  #e7e362, #f1f114, #ffeb3b, #b5ba6f);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }


  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @media only screen and (max-width: 800px) {
      .cube{
          width: 70px;
          height: 70px;
      }

      .cube::before{
        line-height: 70px;
      }
  }

/* end styling light game */


/* to do list */

.task i, .task input {
    padding: 5px;
    cursor: pointer;
    border: solid 1px;
    border-radius: 5px;
    margin-left: 6px;
    margin-bottom: 10px;
}

.task {
    text-align: end;
    width: 400px;
    margin: 5px auto;
    background-color: rgb(116, 226, 43);
    cursor: move;
}

.task.done {
    text-decoration: line-through;
}

/* end to do list */


/* api movies */
    .row{
        margin: 0;
        padding: 0;
    }
    
    .al_movies .card {
        position: relative;
        flex-basis: 18%;
        margin: 10px auto;
        cursor: pointer;
    }

    .al_movies .card img {
        object-fit: cover;
    }
 

    .one_movie {
        padding: 20px;
        text-align: center;
    }

   

    .one_movie img {
        width: auto;
        margin: auto;
        display: block;
        height: 200px;
    }

    .one_movie p{
        margin: 0;
        padding: 0;
    }

    
    .pagenition {    
        text-align: center;
    }

    .pagenition span {
        cursor: pointer;
        padding:3px 7px;
        margin: 0 2px;
        border: solid 1px;
        border-radius: 3px
    }

/*end api movies */


/* overlay */

.overlay {
    transition: 0.7s ease; 
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 888;
    background-color: rgb(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.show_overlay {
    border-radius: 10px;
    left: 50%;
    position: absolute;
    z-index: 999;
    width: 500px;
    height: 80%;
    top: 10%;
    opacity: 1;
    margin-left: -250px;
    background-color:rgb(255,255,255,1);
}


span.close_overlay {
    position: absolute;
    top: -5px;
    right: 2px;
    font-size: 25px;
    padding: 2px 5px 10px 10px;
    cursor: pointer;
    font-weight: bold;
    /* text-transform: lowercase; */
}
/*end overlay */